<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">All Pathao Parcel</h1>
                    </div><!-- /.col -->
                    <div class="col-sm-6">
                        <!-- <router-link to="/under-review-form" class="btn btn-success float-right btn-sm mr-2" >Under Rreview Create</router-link> -->
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="row"> 
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="order_id">Order ID</label>
                                            <input type="text" id="name" v-model="search.order_id" placeholder="Order ID" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="merchant_id">Merchant</label>
                                            <v-select name="merchant_id"
                                                v-model="search.merchant_id"
                                                label="text"
                                                :reduce="item => item.id"
                                                :options= commonObj.merchantList
                                                :placeholder="$t('globalTrans.select')"
                                            />
                                        </div>
                                    </div>  
                                    <div class="col-xl-1 col-lg-1 col-md-1 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="ref_id">Ref. ID</label>
                                            <input type="text" id="name" v-model="search.ref_id" placeholder="Ref. ID" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="mobile">{{ $t('globalTrans.mobile') }}</label>
                                            <input type="text" id="name" v-model="search.mobile" placeholder="Mobile No" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="date">Start Date</label>
                                            <input type="date" id="start_date" v-model="search.start_date" placeholder="Enter Name" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="date">End Date</label>
                                            <input type="date" id="end_date" v-model="search.end_date" placeholder="Enter Name" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-xl-1 col-lg-1 col-md-1 col-sm-6 col-xs-12">
                                        <div class="form-group" style="margin-top:28px;">
                                            <button class="btn btn-info btn-sm" type="submit" @click.prevent="searchData" ><i class="fa fa-search"></i></button>
                                            <button class="btn btn-warning btn-sm ml-1" @click.prevent="reload"><i class="fa fa-sync-alt"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <b-overlay :show="loader">
                                    <div class="table-responsive">
                                        <table class="table table-bordered table-striped table-sm">
                                            <thead>
                                                 <tr class="text-center">
                                                    <th>{{ $t('globalTrans.order_id') }}</th>
                                                    <th>{{ $t('globalTrans.merchant') }}</th>
                                                    <th>Ref ID</th>
                                                    <th>{{ $t('globalTrans.customer') }}</th>
                                                    <th>{{ $t('globalTrans.address') }}</th>
                                                    <th>{{ $t('globalTrans.date') }}</th>
                                                    <th>{{ $t('globalTrans.price') }}</th>
                                                    <th>Collected</th>
                                                    <th>Status</th>
                                                    <th>{{ $t('globalTrans.action') }}</th>                                       
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, key) in listData" :key="key" class="text-center">
                                                    <td>{{ item.id }}</td>
                                                    <td @click="orderLog(item.logs)">{{ item.merchant.business }}</td>
                                                    <td>{{ item.ref_id ? item.ref_id : '-' }}</td>
                                                    <td>{{ item.name }}, {{ item.mobile }}{{ (item.mobile_2  && item.mobile_2 != 0) ? ', '+ item.mobile_2 : '' }}</td>
                                                    <td>{{ item.address }}</td>
                                                    <td>{{ item.date }}</td>
                                                    <td>{{ item.price }}</td>
                                                    <td>{{ item.collected }}</td>
                                                    <td><span :class="'status-'+item.status">{{ getStatus(item.status) }}</span></td>
                                                    <td>
                                                        <button @click="editModal(key, item)" class="btn btn-primary btn-sm mr-1" title="Show"><i class="fas fa-edit"></i></button> 
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="mt-3">
                                        <b-pagination
                                            v-model="pagination.currentPage"
                                            :per-page="pagination.perPage"
                                            :total-rows="pagination.totalRows"
                                            @input="searchData"
                                        />
                                    </div>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>
        <!-- /.content -->

        <div v-if="orderEditModal" class="modal fade show" style="display:block" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-info text-white">
                        <h5 class="modal-title text-center w-100">Order Edit</h5>
                        <button type="button" class="close" aria-label="Close" @click="cancelModal">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div id="accordion">
                            <div class="card">
                                <div class="card-body">
                                    <b-overlay :show="loading">
                                        <ValidationObserver ref="editForm" v-slot="{ handleSubmit }">
                                            <form @submit.prevent="handleSubmit(updateForm)">
                                                <div class="row">
                                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                        <ValidationProvider name="Merchant" vid="merchant_id" v-slot="{errors}" rules="required|min_value:1">
                                                            <div class="form-group">
                                                                <label for="merchant_id">Merchant <span class="text-danger" title="Required">*</span></label>
                                                                <v-select name="merchant_id"
                                                                    v-model="form_edit.merchant_id"
                                                                    label="text"
                                                                    :reduce="item => item.id"
                                                                    :options= commonObj.merchantList
                                                                    :placeholder="$t('globalTrans.select')"
                                                                />
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                        <ValidationProvider name="Reference ID" vid="ref_id" v-slot="{errors}">
                                                            <div class="form-group">
                                                                <label for="ref_id">{{ $t('globalTrans.reference_id') }}</label>
                                                                <input type="text" id="ref_id" v-model="form_edit.ref_id" class="form-control" placeholder="Reference ID" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" disabled />
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                        <ValidationProvider name="Name" vid="name" v-slot="{errors}" rules="required">
                                                            <div class="form-group">
                                                                <label for="name">Name <span class="text-danger" title="Required">*</span></label>
                                                                <input type="text" id="name" v-model="form_edit.name" class="form-control" placeholder="Customer Name" disabled />
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                        <ValidationProvider name="Mobile" vid="mobile" v-slot="{errors}" rules="required|min:11|max:11">
                                                            <div class="form-group">
                                                                <label for="mobile">Mobile No<span class="text-danger" title="Required">*</span></label>
                                                                <input type="text" id="mobile" v-model="form_edit.mobile" class="form-control" placeholder="Mobile No" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" disabled/>
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                        <ValidationProvider name="Alternatvie Mobile" vid="mobile_2" v-slot="{errors}" rules="min:11|max:11">
                                                            <div class="form-group">
                                                                <label for="mobile_2">Alt. Mobile No</label>
                                                                <input type="text" id="mobile_2" v-model="form_edit.mobile_2" class="form-control" placeholder="Alternatvie Mobile No" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" disabled/>
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                        <ValidationProvider name="Price" vid="price" v-slot="{errors}" rules="required">
                                                            <div class="form-group">
                                                                <label for="price">Price <span class="text-danger" title="Required">*</span></label>
                                                                <input type="text" id="price" v-model="form_edit.price" class="form-control" placeholder="Price" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" disabled />
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                        <ValidationProvider name="Collected" vid="collected" v-slot="{errors}" rules="required">
                                                            <div class="form-group">
                                                                <label for="collected">Collected <span class="text-danger" title="Required">*</span></label>
                                                                <input type="text" id="collected" v-model="form_edit.collected" class="form-control" placeholder="Collected" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                        <ValidationProvider name="Address" vid="address" v-slot="{errors}" rules="required">
                                                            <div class="form-group">
                                                                <label for="address">{{ $t('globalTrans.address') }} <span class="text-danger" title="Required">*</span></label>
                                                                <input type="text" id="address" v-model="form_edit.address" class="form-control" placeholder="Address" disabled />
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                        <ValidationProvider name="Instruction (Note)" vid="instruction" v-slot="{errors}">
                                                            <div class="form-group">
                                                                <label for="instruction">{{ $t('globalTrans.instruction') }}</label>
                                                                <input type="text" id="instruction" v-model="form_edit.instruction" class="form-control" placeholder="Instruction (Note)" />
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                        <ValidationProvider name="Description" vid="description" v-slot="{errors}">
                                                            <div class="form-group">
                                                                <label for="description">Description</label>
                                                                <input type="text" id="description" v-model="form_edit.description" class="form-control" placeholder="Description" disabled />
                                                                <span class="text-danger">{{ errors[0] }}</span>
                                                            </div>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                        <div class="form-group">
                                                            <button type="button" class="btn btn-info btn-sm mr-2 mt-30" @click.prevent="updateForm">{{ $t('globalTrans.submit') }}</button>
                                                            <button type="reset" class="btn btn-danger btn-sm mt-30" @click="cancelModal">{{ $t('globalTrans.cancel') }}</button>
                                                        </div>
                                                    </div>
                                                </div> 
                                            </form>
                                        </ValidationObserver>
                                    </b-overlay>                                     
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>

        <div v-if="deleteModal" class="modal fade show" style="display:block" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-default" role="document">
                <b-overlay :show="loader">
                    <div class="modal-content">
                        <div class="modal-header bg-info text-white">
                            <h5 class="modal-title text-center w-100">Delete Confirmation</h5>
                        </div>
                        <div class="modal-body">
                            <input type="hidden" name="del_id" />
                            <h3 class="text-center">Are you sure to delete ?</h3>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" @click="destroyOrder">Delete</button>
                            <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                        </div>
                    </div>
                </b-overlay>
            </div>
        </div>

        <div v-if="orderLogModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-info text-white">
                        <h5 class="modal-title text-center w-100">Order Log</h5>
                        <button type="button" class="close" aria-label="Close" @click="cancelModal">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <table class="table table-bordered table-sm">
                            <tr>
                                <th style="width:30%">Action By</th>
                                <th>Action</th>
                                <th>Date & Time</th>
                            </tr>
                            <tr v-for="(log, index) in order_logs" :key="index">
                                <td>{{ log.operation_by }}</td>
                                <td>{{ log.operation }}</td>
                                <td>{{ log.created_at | dateformat }} at {{ log.created_at | timeformat }}</td>
                            </tr>
                        </table>                       
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-sm btn-danger" @click="cancelModal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import config from '@/config'   
import moment from 'moment'
export default {
    name:'UnderReview',
    data(){
        return {
            loading: false,
            loader: false,
            orderId: '',
            totalParcel: 0,
            order_logs:[],
            search: {
                order_id : '',
                ref_id : '',
                mobile : '',
                merchant_id : '',
                start_date : moment().subtract(1,'d').format('YYYY-MM-DD'),
                end_date: moment().format('YYYY-MM-DD'),
            },
            key:'',
            pagination: {
                currentPage: 1,
                totalRows: 0,
                perPage: this.$store.state.commonObj.perPage,
                slOffset: 1
            },
            exchangeList: [
                { id: 1, text: 'Yes' },
                { id: 2, text: 'NO' },
            ],
            orderEditModal:false,
            deleteModal:false,
            orderLogModal: false
        }
    },
    created(){
        this.loadData();
    },
    watch: {
        loadingState : function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.loadData()
            }
        }
    },
    computed : {
        stateReload () {
            return this.$store.state.stateReload
        },
        customState () {
            return this.$store.state
        },
        listData () {
            return this.$store.state.list
        },
        commonObj () {
            return this.$store.state.commonObj
        },
        authUser () {
            return this.$store.state.authUser
        }
    },
    methods:{
        reload () {
            this.search = Object.assign({}, {
                order_id : '',
                ref_id : '',
                mobile : '',
                merchant_id : '',
                date: moment().format('YYYY-MM-DD'),
            })
            this.orderList = this.ordersData = this.printorders = []
            this.totalSelected = 0
        },
        searchData () {
            this.loadData()
        },
        orderLog (logs) {
            this.order_logs = logs 
            this.orderLogModal = true
        },
        async loadData(){     
            this.loader = true 
            const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })          
            const response = await config.getData('/pathao/parcel-list', params)
            this.loader = false
            if (response.success){
                // this.totalParcel = response.total
                this.$store.dispatch('setList', response.data.data)
                this.paginationData(response.data)
            } else {
                this.totalParcel = 0
                this.$store.dispatch('setList', [])
            } 
        },
        paginationData (data) {
            this.pagination.currentPage = data.current_page
            this.pagination.totalRows = data.total
            this.pagination.perPage = data.per_page
            this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
        },
        deleteOrder(key, orderId){
            this.orderId = orderId;
            this.key = key;
            this.deleteModal = true;
        },
        async destroyOrder(){
            this.loader = true
            this.$store.dispatch('stateReload', true)
            const response = await config.deleteData(`/under-review/destroy/${this.orderId}`)
            this.loader = false
            if (response.success) {
                this.loadData()
                this.deleteModal = false
                this.$toast.success('Data deleted successfully') 
            } else {
                this.$toast.error('Data not deleted') 
            }
            this.$store.dispatch('stateReload', false)
        },
        getThanaList (districtId) {
            console.log('districtId = ', districtId)
            return this.commonObj.thanaList.filter(el => el.district_id == districtId)
        },
        getAreaList (thanaId) {
            return this.commonObj.areaList.filter(el => el.thana_id == thanaId)
        },
        editModal (index, order){
            document.body.classList.add("modal-open");
            this.form_edit = JSON.parse(JSON.stringify(order))
            this.key = index
            this.orderEditModal = true  
        },
         async updateForm () {
            this.loading = true
            this.$store.dispatch('stateReload', true)
            const response = await config.postData('/pathao/update', this.form_edit)
            this.loading = false
            if (response.success) { 
                this.searchData()                   
                this.orderEditModal = false
                    this.$toast.success({
                    title: 'Success',
                    message: 'Data updated successfully',
                    color: '#218838'
                }) 
            } else {
                this.$refs.editForm.setErrors(response.errors)
                this.$toast.error({
                    title: 'Error',
                    message: response.message,
                    color: '#dc3545'
                })
            }
            this.$store.dispatch('stateReload', false)
        },
        cancelModal(){                
            this.orderEditModal = this.deleteModal = this.orderLogModal = false
        },
        getStatus (status) {
            const tmpStatus = this.customState.statusList.find(tmp => tmp.id == status)
            return typeof tmpStatus != 'undefined' ? tmpStatus.text : status
        }
    }
}
</script>

<style>
    @media print{
        body {
            margin-left:-10px;
        }
    }
    .mt-30 {
        margin-top: 30px !important;
    }

    @media only screen and (max-width: 760px),
	(min-device-width: 768px) and (max-device-width: 1024px)  {
	
		/* Force table to not be like tables anymore */
		table, thead, tbody, th, td, tr { 
			display: block; 
		}

        .table-sm th, .table-sm td {
            padding: 0.3rem;
            padding-left: 50%;
        }
		
		/* Hide table headers (but not display: none;, for accessibility) */
		thead tr { 
			position: absolute;
			top: -9999px;
			left: -9999px;
		}
		
		tr { 
            border: 1px solid #ccc;
            margin-bottom: 7px; 
        }
		
		td { 
			/* Behave  like a "row" */
			border: none;
			border-bottom: 1px solid #eee; 
			position: relative;
			padding-left: 50%; 
		}
		
		td:before { 
			/* Now like a table header */
			position: absolute;
			/* Top/left values mimic padding */
			top: 6px;
			left: 6px;
			width: 45%; 
			padding-right: 10px; 
			white-space: nowrap;
		}
		
		/*
		Label the data
		*/
		td:nth-of-type(1):before { content: "Order ID" }
		td:nth-of-type(2):before { content: "Merchant" }
		td:nth-of-type(3):before { content: "Ref ID"}
		td:nth-of-type(4):before { content: "Customer"}
		td:nth-of-type(5):before { content: "Address"}
		td:nth-of-type(6):before { content: "District"}
		td:nth-of-type(7):before { content: "Date"}
		td:nth-of-type(8):before { content: "Price"}
		td:nth-of-type(9):before { content: "Action"}
	}
	
	/* Smartphones (portrait and landscape) ----------- */
	@media only screen
	and (min-device-width : 320px)
	and (max-device-width : 480px) {
		body { 
			padding: 0; 
			margin: 0; 
			width: 320px; }
		}
	
	/* iPads (portrait and landscape) ----------- */
	@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
		body { 
			width: 495px; 
		}
	}
</style>